<template>
  <div class="home">
    <v-row >
      <div><h2 class="page-title-bar">
      <i class="ico ico-overview"></i>{{ $t('Overview') }}
    </h2></div>
      <div class="label-txt" style="margin-left:10px;margin-top:10px"><span >{{LastModifiedDate}}</span><span style="margin-left:5px">({{LastTimezone}})</span></div>
    </v-row>

    <div>
      <div class="state-info">
        <ul>
          <li class="state-danger">{{ $t('DANGER') }}</li>
          <li class="state-caution">{{ $t('CAUTION') }}</li>
          <li class="state-stable">{{ $t('STABLE') }}</li>
        </ul>
      </div>
      <div class="main-layout layout01">
        <div class="main-section">
          <h3 class="tit-section">{{ $t('Label Status') }}</h3>
          <div class="group">
            <v-btn class="stateBox">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { status: 'SUCCESS' } },
                    updatedLabelCount
                  )
                "
              >
                <dl>
                  <dt v-if="CUSTOMER !=='METRO'">{{ $t('Update Success') }}</dt>
                  <dt style="border-color: rgb(100, 184, 106);" v-if="CUSTOMER ==='METRO'">{{ $t('Labels OK') }}</dt>
                  <dd>
                    <p>
                      <strong>{{ percentUpdateSuccess }}%</strong>
                    </p>
                    <p v-if="CUSTOMER !=='METRO'">
                      {{ updatedLabelCount | currency }}ea /
                      {{ totalUpdatedLabelCount | currency }}ea
                    </p>
                    <p v-if="CUSTOMER ==='METRO'">
                      {{ updatedLabelCount | currency }}  /
                      {{ totalUpdatedLabelCount | currency }}
                    </p>
                  </dd>
                  <span v-if="CUSTOMER ==='METRO'" class="LabelStatusborder" style="border: solid rgb(100, 184, 106);"></span>
                </dl>
              </router-link>
            </v-btn>
            <v-btn class="stateBox">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { status: 'PROCESSING' } },
                    inProgressLabelCount
                  )
                "
              >
                <dl>
                  <dt v-if="CUSTOMER !=='METRO'">{{ $t('Processing') }}</dt>
                  <dt style="border-color: rgb(100, 184, 106);" v-if="CUSTOMER ==='METRO'">{{ $t('In Update') }}</dt>
                  <dd>
                    <p>
                      <strong>{{ percentProcessing }}%</strong>
                    </p>
                    <p v-if="CUSTOMER !=='METRO'">
                      {{ inProgressLabelCount | currency }}ea /
                      {{ totalUpdatedLabelCount | currency }}ea
                    </p>
                    <p v-if="CUSTOMER ==='METRO'">
                      {{ inProgressLabelCount | currency }}/
                      {{ totalUpdatedLabelCount | currency }}
                    </p>
                  </dd>
                  <span v-if="CUSTOMER ==='METRO'" class="LabelStatusborder" style="border: solid rgb(100, 184, 106);"></span>
                </dl>
              </router-link>
            </v-btn>
            <v-btn ref="UpdateFailure" class="stateBox">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { status: 'TIMEOUT' } },
                    notUpdatedLabelCount,
                    problemStoreVisible('updateFailure')
                  )
                "
              >
                <dl>
                  <dt v-if="CUSTOMER !=='METRO'">{{ $t('Update Failure') }}</dt>
                  <dt v-if="CUSTOMER ==='METRO'">{{ $t('Labels Not OK') }}</dt>
                  <dd>
                    <p>
                      <strong>{{ percentUpdateFailure }}%</strong>
                    </p>
                    <p v-if="CUSTOMER !=='METRO'">
                      {{ notUpdatedLabelCount | currency }}ea /
                      {{ totalUpdatedLabelCount | currency }}ea
                    </p>
                    <p v-if="CUSTOMER ==='METRO'">
                      {{ notUpdatedLabelCount | currency }} /
                      {{ totalUpdatedLabelCount | currency }}
                    </p>
                  </dd>
                  <span v-if="CUSTOMER ==='METRO'" class="LabelStatusborder" style="border: solid rgb(226, 112, 112);"></span>
                </dl>
              </router-link>
            </v-btn>
            <v-btn class="stateBox">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '3100',
                    { name: 'Product Info' },
                    totalProductCount
                  )
                "
              >
                <dt>{{ $t('Total Product') }}</dt>
                <dd>
                  <p>
                    <strong>{{ totalProductCount | currency }}</strong>
                  </p>
                </dd>
              </router-link>
            </v-btn>
            <dl class="stateBox" style="margin-left: 10px">
              <dt>{{ $t('Total Assigned Product') }}</dt>
              <dd>
                <p>
                  <strong>{{ totalAssignedProductCount | currency }}</strong>
                </p>
              </dd>
            </dl>
            <v-btn class="stateBox ml_10" v-if="CUSTOMER !=='METRO'">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { status: StatusParam } },
                    totalLabelCount
                  )
                "
              >
                <dl>
                  <dt>{{ $t('Total Label') }}</dt>
                  <dd>
                    <p >
                      <strong>{{ totalUpdatedLabelCount | currency }}</strong>
                    </p>
                  </dd>
                </dl>
              </router-link>
            </v-btn>
            <v-btn class="stateBox ml_10" v-if="CUSTOMER ==='METRO'">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { status: StatusParam } },
                    totalLabelCount
                  )
                "
              >
                <dl>
                  <dt>{{ $t('Total Label') }}</dt>
                  <dd>
                    <p>
                      <strong>{{ totalUpdatedLabelCount | currency }}</strong>
                    </p>

                  </dd>
                </dl>
              </router-link>
            </v-btn>
          </div>
        </div>
         <!-- Exception Status -->
        <div class="main-section" v-if="(CUSTOMER ==='LCL') || (CUSTOMER ==='METRO')">
          <h3 class="tit-section">{{ $t('Exception Status') }}</h3>
           <v-btn ref="LowBattery" class="stateBox" style="margin-bottom: 20px;width:100%">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { battery: 'VERYGOOD', status: StatusParam } },
                    veryGoodBatteryCount,
                    problemStoreVisible('verygoodBattery')
                  )
                "
              >
                <dt style="border-bottom:1px solid #197FE5">{{ $t('Battery') }}</dt>
                <dd style="width:25%;float:right">
                  <p>
                    <strong style="color:#197FE5">{{ veryGoodBatteryCount | currency }}</strong>
                  </p>
                  <p v-if="CUSTOMER !=='METRO'">
                      50~100%
                    </p>
                    <p v-if="CUSTOMER ==='METRO'" style="color:#197FE5">
                      VERYGOOD
                    </p>
                </dd>
              </router-link>
               <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { battery: 'GOOD',status: StatusParam } },
                    goodBatteryCount,
                    problemStoreVisible('goodBattery')
                  )
                "
              >
                <dt style="border-bottom:1px solid #64B86A"></dt>

                <dd style="width:25%;float:right">

                  <p>
                    <strong style="color:#64B86A">{{ goodBatteryCount | currency }}</strong>
                  </p>
                   <p v-if="CUSTOMER !=='METRO'">
                      25~49%
                    </p>
                    <p v-if="CUSTOMER ==='METRO'" style="color:#64B86A">
                      GOOD
                    </p>
                </dd>
              </router-link>
               <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { battery: 'LOW',status: StatusParam } },
                    lowBatteryCount,
                    problemStoreVisible('lowBattery')
                  )
                "
              >
                <dt style="border-bottom:1px solid #F5B45F"></dt>

                <dd style="width:25%;float:right">
                  <p>
                    <strong style="color:#F5B45F">{{ lowBatteryCount | currency }}</strong>
                  </p>
                   <p v-if="CUSTOMER !=='METRO'">
                      10~24%
                    </p>
                    <p v-if="CUSTOMER ==='METRO'" style="color:#F5B45F">
                      LOW
                    </p>
                </dd>
              </router-link>
               <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { battery: 'REPLACE',status: StatusParam } },
                    replaceBatteryCount,
                    problemStoreVisible('replaceBattery')
                  )
                "
              >
                <dt style="border-bottom:1px solid #E27070"></dt>

                <dd style="width:25%;float:right">
                  <p>
                    <strong style="color:#E27070">{{ replaceBatteryCount | currency }}</strong>
                  </p>
                   <p v-if="CUSTOMER !=='METRO'">
                      0~9%
                    </p>
                    <p v-if="CUSTOMER ==='METRO'" style="color:#E27070">
                      REPLACE
                    </p>
                </dd>
              </router-link>
            </v-btn>
            <br>
          <div class="group" style="width:100%" >
            <v-row class="pa-0 ma-0">
            <v-btn ref="LabelOffline" class="stateBox col">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { network: 'false',status: StatusParam } },
                    offlineLabelCount,
                    problemStoreVisible('labelOffline')
                  )
                "
              >
                <dt>{{ $t('Offline') }}</dt>
                <dd>
                  <p>
                    <strong v-if="offlineLabelStatus!=='N/A'">{{ offlineLabelStatus | currency }}</strong>
                    <strong v-else>{{ offlineLabelStatus }}</strong>
                  </p>
                </dd>
              </router-link>
            </v-btn>

            <v-btn ref="BadSignal" class="stateBox col">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { signal: 'BAD', status: StatusParam} },
                    badSignalLabelCount,
                    problemStoreVisible('badSignal')
                  )
                "
              >
                <dt>{{ $t('Bad RF quality') }}</dt>
                <dd>
                  <p>
                    <strong>{{ badSignalLabelCount | currency }}</strong>
                  </p>
                </dd>
              </router-link>
            </v-btn>
            <v-btn ref="Misused" class="stateBox col" style="margin-left:10px;">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { misusedLabelType: 'All' ,status: StatusParam} },
                    misUsedLabelCount,
                    problemStoreVisible('misused')
                  )
                "
              >
                <dt>{{ $t('Misused') }}</dt>
                <dd>
                  <p>
                    <strong>{{ misUsedLabelCount | currency }}</strong>
                  </p>
                </dd>
              </router-link>
            </v-btn>
            </v-row>
          </div>
        </div>
        <!-- Exception Status -->
        <div class="main-section" v-if="(CUSTOMER !=='LCL') && (CUSTOMER !=='METRO')">
          <h3 class="tit-section">{{ $t('Exception Status') }}</h3>
          <div class="group">
            <v-btn ref="LabelOffline" class="stateBox">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { network: 'false',status: StatusParam } },
                    offlineLabelCount,
                    problemStoreVisible('labelOffline')
                  )
                "
              >
                <dt>{{ $t('Label Offline') }}</dt>
                <dd>
                  <p>
                    <strong v-if="offlineLabelStatus!=='N/A'">{{ offlineLabelStatus | currency }}</strong>
                    <strong v-else>{{ offlineLabelStatus }}</strong>
                  </p>
                </dd>
              </router-link>
            </v-btn>
            <v-btn ref="LowBattery" class="stateBox">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { battery: 'BAD',status: StatusParam } },
                    lowBatteryCount,
                    problemStoreVisible('lowBattery')
                  )
                "
              >
                <dt>{{ $t('Low Battery') }}</dt>
                <dd>
                  <p>
                    <strong>{{ lowBatteryCount | currency }}</strong>
                  </p>
                </dd>
              </router-link>
            </v-btn>
            <v-btn ref="BadSignal" class="stateBox">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { signal: 'BAD' ,status: StatusParam} },
                    badSignalLabelCount,
                    problemStoreVisible('badSignal')
                  )
                "
              >
                <dt>{{ $t('Bad Signal') }}</dt>
                <dd>
                  <p>
                    <strong>{{ badSignalLabelCount | currency }}</strong>
                  </p>
                </dd>
              </router-link>
            </v-btn>
            <v-btn ref="Misused" class="stateBox">
              <router-link
                :to="{}"
                @click.native.prevent="
                  goDirectPage(
                    '2100',
                    { name: 'Label Info', params: { misusedLabelType: 'All' ,status: StatusParam} },
                    misUsedLabelCount,
                    problemStoreVisible('misused')
                  )
                "
              >
                <dt>{{ $t('Misused') }}</dt>
                <dd>
                  <p>
                    <strong>{{ misUsedLabelCount | currency }}</strong>
                  </p>
                </dd>
              </router-link>
            </v-btn>
          </div>
        </div>
      </div>
      <!-- Gateway Status -->
      <div class="main-layout layout02">
        <div class="main-section">
          <div v-if="CUSTOMER ==='METRO'">
          <div v-if="totalGwCount ===0" ><h3 class="tit-section" >{{ $t('Gateway Status') }}</h3></div>
          <div v-if="offlineGwCount ===0 && totalGwCount !==0" ><h3 class="tit-section" style="display: flex;align-items: center;justify-content: space-between;">{{ $t('Gateway Status') }}<span class="Onlinedot"></span></h3></div>
          <div v-if="offlineGwCount !==0"><h3 class="tit-section" style="display: flex;align-items: center;justify-content: space-between;">{{ $t('Gateway Status') }}<span class="Offlinedot"></span></h3></div>
            </div>
          <h3 class="tit-section" v-if="CUSTOMER !=='METRO'">{{ $t('Gateway Status') }}</h3>
          <div class="group">
            <v-btn ref="GatewayStatus" class="stateBox">
              <dl>
                <router-link
                  :to="{}"
                  @click.native.prevent="
                    goDirectPage(
                      '4100',
                      { name: 'Gateway Info', params: { network: 'offline' } },
                      offlineGwCount,
                      problemStoreVisible('gatewayOffline')
                    )
                  "
                >
                  <dt>{{ $t('Gateway Offline') }}</dt>
                  <dd>
                    <p>
                      <strong>{{ offlineGwCount | currency }}</strong>
                    </p>
                  </dd>
                </router-link>
              </dl>
            </v-btn>
            <v-btn class="stateBox">
              <dl>
                <router-link
                  :to="{}"
                  @click.native.prevent="
                    goDirectPage(
                      '4100',
                      { name: 'Gateway Info', params: { network: 'all' } },
                      totalGwCount
                    )
                  "
                >
                  <dt>{{ $t('Total Gateway') }}</dt>
                  <dd>
                    <p>
                      <strong>{{ totalGwCount | currency }}</strong>
                    </p>
                  </dd>
                </router-link>
              </dl>
            </v-btn>
          </div>
        </div>
        <!-- Update Status -->
        <div class="main-section">
          <h3 class="tit-section">{{ $t('Update Status') }}</h3>
          <div
            id="progress-linear"
            class="graphBox"
            v-bind:style="{ display: progressBarShow }"
          >
            <h4 class="tit-sub">{{ $t('Latest Update Status') }}</h4>
            <div class="lineBar">
              <v-slider
                readonly
                dense
                hide-details
                track-color="#ffffff"
                v-model="percentProgress"
                class="linear-graph"
              ></v-slider>
              <div class="bar_data">
                <strong>{{ percentProgress }}%</strong>
                <p>
                  {{ lastUpdateInprogressCount | currency }}ea /
                  {{ lastUpdatetotalCount | currency }}ea
                </p>
              </div>
            </div>
          </div>
          <div class="graphBox">
            <h4  v-if="CUSTOMER !=='METRO'" class="tit-sub">{{ $t('Total Update Order') }}</h4>
            <h4  v-if="CUSTOMER ==='METRO'" class="tit-sub">{{ $t('Total Update') }}</h4>
            <mixChart
              v-show="progressBarShow === 'none'"
              :height="250"
              :updateStatus="updateStatus"
            ></mixChart>
            <mixChart
              v-show="progressBarShow === 'block'"
              :height="160"
              :updateStatus="updateStatus"
            ></mixChart>
          </div>
        </div>
      </div>
    </div>

    <!-- //For Rogers -->
    <div class="row" v-if="CUSTOMER==='ROGERS'">
      <div class="col">
        <div class="col-2 btnclass" v-if="!storeselected">
        <v-btn class="col-12 fileuploadbtn" @click="openFileuploadPopup">File Upload</v-btn>
        <br>
        <v-btn class="col-12 mt-3 fileuploadbtn"  @click="openMessageboardPopup">Message Board</v-btn>
        </div>
        <div class="col-2 btnclass" v-if="storeselected">
        <!-- <v-btn class="col-12 fileuploadbtn" @click="openassignPopup">Assign</v-btn>
        <br>
        <v-btn class="col-12 mt-3 fileuploadbtn"  @click="openUnassignPopup">Unassign</v-btn> -->
        </div>
        <div class="col-10 mt-3 anouncementclass custom-scrollbar">
        <pre>
          {{ messagetextdata }}
        </pre>
        </div>
        <br clear=“all” />
      </div>
    </div>

    <!-- //File Upload Dialog -->
    <v-dialog v-model="Fileuploadpopup" scrollable
       persistent width="500">
      <v-card class="popup add_store_popup">
        <v-card-title>
        <h3 class="page-title-bar mb-2">
          <i class="ico" style="margin-right: 3px;">
            <img width="33" height="32" src="@/assets/img/ico-upload-file.gif"/></i>{{ $t('File Upload') }}
        </h3>
      </v-card-title>
      <v-card-text >
        <v-row>
          <v-col>
              <p class="txt-s mb-2">Select File (Extension :.xlsm) *View file format</p>
                <v-file-input
                  outlined
                  dense
                  hide-details
                  prepend-icon
                  solo
                  flat
                  persistent-hint
                  :placeholder="`${$t('Select file')}`"
                  class="form-file"
                  multiple="true"
                  v-model="filesLater"
                    @change="appendFilesLater($event)"
                    append-icon="mdi-file-upload">
                  <!-- <template v-slot:append>
                    <img
                      width="24"
                      height="24"
                      src="@/assets/img/ico-upload-file.gif"
                    />
                  </template> -->
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
        <v-col>
          <p class="label-txt">{{ $t('Selected Files') }}</p>
          <ul class="selectedFile-list mt-2">
            <li v-for="file in filesToUploadLater" :key="file.name">
              <p>{{ file.name }}</p>
              <v-btn @click="removeFileLater(file.name)" text icon
                ><img width="24" height="24" src="@/assets/img/ico-delete2.png"
              /></v-btn>
            </li>
            <template v-if="filesToUploadLater.length === 0">
                      <li>
                        <p>No Files selected</p>
                      </li>
            </template>
          </ul>
        </v-col>
        </v-row>
              <!-- <v-row>
                <v-col>
                <div class="row">
                  <v-checkbox class="mt-0 pt-0"
                    hide-details
                    style="margin-left: 7px;"
                    v-model="Schedulefileupload"
                  ></v-checkbox>
                  <span style="font-size:15px">Scheduling</span
                  >
                </div>
              </v-col>
              </v-row> -->
              <!-- <div style="height:60px">
              <div v-if="!Schedulefileupload" style="margin-left: 26px;margin-top: -13px;">
                  <span class="label-txt" style="margin-top: 23px">*If you don't set the schedule,it will be uploaded now.</span>
              </div>
              <div v-if="Schedulefileupload">
                  <v-menu v-model="schedule.showStartDatePicker" :close-on-content-click="false" transition="scale-transition" offset-y  min-width="250px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="scheduleTime"
                    @click:clear="scheduleTime = null"
                    :label="`${$t('Schedule Time *')}`"
                    :placeholder="`${$t('Select Schedule Time')}`"
                    class="form-select mb-4 col mandatory"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    readonly
                    clearable
                    prepend-icon=""
                    append-icon="mdi-calendar-month"
                    ref="calendarstart"
                    @click="setCurrentDate"
                    @click:append="$refs.calendarstart.$el.querySelector('input').click()">
                  </v-text-field>
                </template>
                <date-picker
                  mode="dateTime"
                  v-model="startTime"
                  :min-date="new Date()"
                  :minute-increment="30"
                  :is-required="true"
                  is24hr />
              </v-menu>
              </div>
              </div> -->
              <div class="label-txt mt-4">
               <p> * Required Files</p>
               <p> - One or more of the following</p>
               <div class="ml-3">
                 <p>:Rogers Consumer Pricing Chart.xlsm</p>
                 <p>:Fido Consumer Pricing Chart.xlsm</p>
                 <p>:Rogers and Fido Accessory Pricing Chart.xlsm</p>
               </div>
              </div>
              </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text :disabled="filesToUploadLater.length===0" icon @click="uploafiles('now');Fileuploadpopup = false" class="btn">{{$t('Upload Now')}}</v-btn>
          <v-btn text :disabled="filesToUploadLater.length===0" icon @click="uploafiles('later');Fileuploadpopup = false" class="btn">{{$t('Upload Later')}}</v-btn>

          <v-btn text icon @click="Fileuploadpopup = false" class="btn">{{$t('Cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

       <!-- //Message Board Dialog -->
    <v-dialog v-model="MessageBoardpopup" scrollable
       persistent width="500">
      <v-card class="popup add_store_popup">
        <v-card-title>
        <h3 class="page-title-bar">
          <i class="ico" style="margin-right: 10px;">
            <img width="33" height="32" src="@/assets/img/ico-tit-messageboard.png"/></i>{{ $t('Message Board') }}
        </h3>
      </v-card-title>
        <textarea class="messageboardClass" v-model="messagetextdata"  style="width:100%"></textarea>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="messagetextdataSave();MessageBoardpopup = false" class="btn">{{$t('Save')}}</v-btn>
          <v-btn text icon @click="MessageBoardpopup = false;cancelmessageboard()" class="btn">{{$t('Cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 문제발생 Store 리스트 팝업 -->
    <v-dialog
      v-model="problemDialog"
      scrollable
       persistent
      width="800"
    >
      <v-card class="popup add_store_popup">
        <v-card-title>
        <h3 class="page-title-bar">
          <i class="ico ico-overview"></i>{{ $t('Abnormal Store') }}
        </h3></v-card-title>
        <v-card-text >
        <v-data-table
          :headers="translateHeaders(ProblemHeaders)"
          :items="ProblemItems"
          :hide-default-footer="true"
          item-key="id"
          class="tbl-type01"
          disable-pagination
          fixed-header
          :height="ProblemItems.length > 0 ? '560px' : 'auto'"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.link`]="{ item }">
            <v-btn text @click="goProblemLink(item)"
              ><img src="@/assets/img/ico_link.png" alt="aaa"
            /></v-btn>
          </template>
        </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="problemDialog = false" class="btn">{{
            $t('Close')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Password Popup starts -->
  <v-dialog
    v-model="dialog_passwordChange"
    width="950"
    max-height="80%"
    v-if="passwordPolicy"
  >
  <v-card class="popup esl-detail">
    <div class="popup-titbox mb-0" style="display: flex;flex-direction: column;">
        <h3 class="page-title-bar" style="font-weight: 600;margin-left: -2px;"><v-icon size="25" color="#001E38">mdi-lock</v-icon>{{ $t('Change Temporary Password') }}</h3>
        <div>You logged in with a temporary password. Please enter a new password</div>
    </div>

    <div class="d-flex col-12 mt-5" style="padding: 0px !important;margin-left: -15px;">
      <div class="popup-titbox mb-0 col-6" style="display: flex;flex-direction: column;">
        <div>
            <v-text-field style="width: 300px;font-weight: 600;"
              v-model="newPassword"
              :label="$t('New Password *')"
              :placeholder="$t('Input New Password')"
              class="form-input"
              outlined
              dense
              type="password"
              @input="passwordValidate"
              hide-details
            ></v-text-field>
            <v-text-field style="width: 300px;font-weight: 600;"
              v-model="confirmnewPassword"
              :label="$t('Confirm New Password *')"
               :placeholder="$t('Input Confirm New Password')"
              class="form-input mt-5"
              outlined
              dense
              type="password"
              hide-details
            ></v-text-field>
        </div>
      </div>
      <div class="popup-titbox mb-0 col-6" style="display: flex;flex-direction: column;">
        <p class="frmValidation" ><v-icon class="mr-4" :class="{iconClassNot: !has_length, iconClass: has_length}">mdi-checkbox-marked-circle</v-icon>Contains 8 to 15 characters.</p>
        <p class="frmValidation mt-3" ><v-icon class="mr-4" :class="{iconClassNot: !has_uppercase, iconClass: has_uppercase}">mdi-checkbox-marked-circle</v-icon>Include at least 1 uppercase letter.</p>
        <p class="frmValidation mt-3" ><v-icon class="mr-4" :class="{iconClassNot: !has_lowercase, iconClass: has_lowercase}">mdi-checkbox-marked-circle</v-icon>Include at least 1 lower case letter.</p>
        <p class="frmValidation mt-3" ><v-icon class="mr-4"  :class="{iconClassNot: !has_number, iconClass: has_number}">mdi-checkbox-marked-circle</v-icon>Include at least 1 number.</p>
        <p class="frmValidation mt-3" ><v-icon class="mr-4" :class="{iconClassNot: !has_special, iconClass: has_special}">mdi-checkbox-marked-circle</v-icon>Include at least 1 special character.<br/>
        <span style="font-size: 14px;margin-left: 34px;">(*List all special characters and not just etc)</span></p>
        <p class="frmValidation mt-3" ><v-icon class="mr-4" :class="{iconClassNot: has_consicutive, iconClass: !has_consicutive}">mdi-checkbox-marked-circle</v-icon>Not use 3 consecutive characters. (abc, 123)</p>
        <p class="frmValidation mt-3" ><v-icon class="mr-4" :class="{iconClassNot: has_repeat, iconClass: !has_repeat}">mdi-checkbox-marked-circle</v-icon>Not repeat 3 characters. (aaa, 777)</p>
        <p class="frmValidation mt-3" ><v-icon class="mr-4" :class="{iconClassNot: !has_space, iconClass: has_space}">mdi-checkbox-marked-circle</v-icon>Not use space.</p>
      </div>
    </div>
    <div style="padding:30px;">
        <v-card-actions class="d-flex justify-center mt-0">
          <v-btn :disabled="!savebutton"  text icon @click="savePasswordwithpolicy" class="btn">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </div>
  </v-card>
  </v-dialog>
  <v-dialog
    v-model="dialog_passwordChange"
    width="620"
    max-height="80%"
    v-if="!passwordPolicy"
  >
  <v-card class="popup esl-detail">
    <div class="popup-titbox mb-0" style="display: flex;flex-direction: column;">
      <v-card-title>
        <h3 class="page-title-bar" style="font-weight: 600;margin-left: -2px;"><v-icon size="25" color="#001E38">mdi-lock</v-icon>{{ $t('Change Temporary Password') }}</h3>
      </v-card-title>
        <div style="font-weight: 200;">You logged in with a temporary password. Please enter a new password</div>
    </div>
    <v-card-text >
    <div class="d-flex col-12 mt-5" style="padding: 0px !important;">
      <div class="popup-titbox mb-0" style="display: flex;flex-direction: column;">
        <div>
            <v-text-field style="font-weight: 600;width: 503px;"
              v-model="newPassword"
              :label="$t('New Password *')"
              :placeholder="$t('Input New Password')"
              class="form-input"
              outlined
              dense
              type="password"
              hide-details
            ></v-text-field>
            <v-text-field style="width: 300px;font-weight: 600;width: 503px;"
              v-model="confirmnewPassword"
              :label="$t('Confirm New Password *')"
               :placeholder="$t('Input Confirm New Password')"
              class="form-input mt-5"
              outlined
              dense
              type="password"
              hide-details
            ></v-text-field>
        </div>
      </div>

    </div>
    </v-card-text>
    <div>
        <v-card-actions class="d-flex justify-center mt-10">
          <v-btn  text icon @click="savePasswordwithoutpolicy" class="btn">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </div>
  </v-card>
  </v-dialog>

<!-- EMAIL POPUP starts -->
<v-dialog
      v-model="emailDialog"
      width="650"
      persistent
      scrollable
      max-height="90%"
    >
      <v-card class="popup esl-detail">
        <v-card-title>
          <div
            class="popup-titbox mb-0"
            style="display: flex;flex-direction: column;"
          >
            <h3
              class="page-title-bar"
              style="font-weight: 600;margin-left: -2px;"
            >
              <v-icon size="25" color="#001E38">mdi-lock</v-icon
              >{{ $t("Update E-mail") }}
            </h3>
            <div style="font-weight: 200;">
              {{ $t('If you update your E-mail, you can easily find your password and receive your report by E-mail.')}}
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="d-flex col-12 mt-5" style="padding: 0px !important;">
            <div
              class="popup-titbox mb-0"
              style="display: flex;flex-direction: column;"
            >
              <div>
                <v-text-field
                  style="font-weight: 600;width: 503px;"
                  v-model="updateEmail"
                  :label="`${$t('E-Mail')}`"
                  :placeholder="$t('Input the Email')"
                  class="form-input"
                  outlined
                  dense
                  type="text"
                  hide-details
                ></v-text-field>
                <br /><br />
                <div style="display:flex">
                  <v-checkbox
                    hide-details
                    class="form-checkbox ml-2"
                    v-model="checkedEmail"
                    @change="checkEmail"
                  ></v-checkbox>
                  <span>{{ $t("Don't ask me again")}}</span><br /><br />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <div>
          <v-card-actions class="d-flex justify-center mt-3">
            <div style="display: flex;justify-content: center">
              <v-btn text class="btn ml-3" @click="updateEmailAddress"
                >{{ $t("Update") }} </v-btn
              >&nbsp;
              <v-btn text class="btn ml-3" @click="updateEmailAddressLater"
                >{{ $t("Update Later") }}
              </v-btn>
            </div>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  <!--Message -->

      <v-dialog v-model="messageDialog" width="300">
        <v-card class="popup add_store_popup" align="center" justify="center">
          <v-row>
            <v-col>
              <span v-text="messageText"></span>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-center">
            <v-btn text icon class="btn" @click="afterUpdatePassword"
              >{{$t("OK")}}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import mixChart from '@/components/HomeMixChart'
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import configs from '@/plugins/configs'
import Cookies from 'js-cookie'

// Rogers
// import { DatePicker } from 'v-calendar'
import moment from 'moment'
import MsgBoxParent from '@/mixins/MsgBoxParent'
import FileUploader from '@/mixins/FileUploader'

// import { getPasswordValidation } from '@/plugins/passwordValidator'

const requests = {
  SavePassword: {
    method: 'put',
    url: '/common/account/password/temporary'
  },
  logoutUser: {
    method: 'get',
    url: '/api/common/logout'
  },
  updateEmail: {
    method: 'put',
    url: '/common/account/email'
  },

  // Rogers
  getmessageBoard: {
    method: 'get',
    url: '/api/common/config/system/announce'
    // url: '/portal/dashboard/settings?category=dashboardSettings'
  },
  postmessageBoard: {
    method: 'post',
    url: '/api/common/config/system/announce'
    // url: '/portal/dashboard/settings?category=dashboardSettings'
  },
  getvaluebyproducttype: {
    method: 'get',
    url: '/portal/articles/article/content'
  },
  getTemplatestype: {
    method: 'get',
    url: '/api/common/templates/type'
  },
  getTemplatestypebylabel: {
    method: 'get',
    url: '/api/common/labels/type/one'
  },
  gettemplatesbylabeltype: {
    method: 'get',
    url: '/api/common/templates'
  },
  toassignlabels: {
    method: 'post',
    url: '/api/common/labels/link'
  },
  tounassignlabels: {
    method: 'post',
    url: '/api/common/labels/unlink'
  },
  touploadfiles: {
    method: 'post',
    url: '/converter/copy/files'
  },
  uploadFilepath: {
    method: 'get',
    url: '/api/common/file/update'
  },
  tocreatelog: {
    method: 'post',
    url: '/portal/dashboard/log/createnewlog'
  }
  // Rogers End
}
export default {
  name: 'Summary',
  components: {
    mixChart
    // DatePicker
  },
  // Rogers
  mixins: [FileUploader, MsgBoxParent],
  data () {
    const startTime = new Date()
    startTime.setMinutes(0, 0, 0)
    //
    return {
      serverType: process.env.VUE_APP_PRODUCTION_TYPE,
      ProblemHeaders: [
        {
          text: '#',
          value: 'no',
          width: '10%',
          sortable: false,
          align: 'center'
        },
        { text: 'STORE NAME', value: 'storeName', sortable: false },
        {
          text: 'STORE CODE',
          width: '30%',
          value: 'storeCode',
          sortable: false
        },
        {
          text: 'Abnormal Label',
          width: '20%',
          value: 'count',
          sortable: false
        },
        { text: '', value: 'link', width: '10%', sortable: false }
      ],
      company: 'company',
      account: 'account',
      progressBarShow: 'block',
      mixChartActive: true,
      // Label Status
      updatedLabelCount: 0,
      percentUpdateSuccess: 0,
      inProgressLabelCount: 0,
      totalUpdatedLabelCount: 0,
      percentProcessing: 0,
      notUpdatedLabelCount: 0,
      percentUpdateFailure: 0,
      totalProductCount: 0,
      totalAssignedProductCount: 0,
      totalLabelCount: 0,
      // Exception Status
      offlineLabelCount: 0,
      offlineLabelStatus: 0,
      veryGoodBatteryCount: 0,
      goodBatteryCount: 0,
      lowBatteryCount: 0,
      replaceBatteryCount: 0,
      badSignalLabelCount: 0,
      misUsedLabelCount: 0,
      // Gateway Status
      offlineGwCount: 0,
      totalGwCount: 0,
      // Update Status
      percentProgress: 0,
      lastUpdateInprogressCount: 0,
      lastUpdatetotalCount: 0,
      updateStatus: [],
      problemDialog: false,
      problemType: '',
      problemLink: {},
      ProblemItems: [],
      LastModifiedDate: '',
      LastTimezone: '',
      CUSTOMER: configs.Customer,

      dialog_passwordChange: false,
      newPassword: '',
      confirmnewPassword: '',
      has_length: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      has_consicutive: false,
      has_repeat: false,
      has_space: true,
      savebutton: false,
      passwordPolicy: false,
      messageDialog: false,
      emailDialog: false,
      updateEmail: '',
      messageText: '',
      checkedEmail: false,

      // Rogers
      Fileuploadpopup: false,
      MessageBoardpopup: false,
      files: [],
      filesToUploadLater: [],
      Schedulefileupload: false,
      schedule: {},
      scheduleTime: '',
      startTime,
      storeselected: false,
      assignpopup: false,
      unassignpopup: false,
      product: ['Product ID', 'Product Description'],
      productType: '',
      producttypechange: false,
      productlist: [],
      productdataselect: 'Product ID',
      templatelist: [],
      templatelistvalue: '',
      messagetextdata: '',
      labelCode: '',
      templatetypebylabel: false,
      productValue: '',
      unassignlables: '',
      filesToUpload: [],
      fileUploadVisible: false,
      filesLater: [],
      finalimagearray: [],
      StatusParam: ['TIMEOUT', 'PROCESSING', 'SUCCESS'],
      emptymesssge: false

      // Rogers End
    }
  },
  computed: {
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE']
    }
  },
  watch: {
    selectedStore (newStore, prevStore) {
      if (newStore !== prevStore) {
        this.initOverview(newStore)
      }
      // Rogers
      if (this.$store.state.dataStore.selectedStore.code !== '') {
        this.storeselected = true
      } else {
        this.storeselected = false
      }
    },
    problemDialog (flag) {
      if (flag) {
        // Initiate the popup on open.
        this.getProblemList()
      }
    },
    // Rogers
    startTime (value) {
      this.scheduleTime = moment(value).format('YYYY-MM-DD HH:mm')
    },
    scheduledTime (val) {
      console.log(val)
    }
    // End
  },
  mounted () {
    this.getUserDetails()
    EventBus.$emit('enableSelectedStores', true)
    this.initOverview(this.selectedStore)
    if (this.$store.state.auth.user.temporaryLogin === 'true') {
      this.dialog_passwordChange = true
    }
    if (this.$store.state.auth.user.pwPolicyEnable === 'true') {
      this.passwordPolicy = true
    }
    // Rogers
    if (this.$store.state.dataStore.selectedStore.code !== '') {
      this.storeselected = true
    } else {
      this.storeselected = false
    }
    if (this.CUSTOMER === 'ROGERS') {
      this.getmessageBoard()
    }
    // Rogers End
    const serverType = this.$store.state.auth.user.serverType
    if (serverType === 'SINGLE') { // serverType === 'SINGLE' 이면 Area Group Management 숨김
      this.toPreSelectStoreforSingleServer()
    }
  },
  methods: {

    // Start Password and Email Update
    savePasswordwithpolicy () {
      const url = configs.ApiUrl + requests.SavePassword.url
      console.log(url)
      if (this.newPassword !== '' && this.confirmnewPassword !== '') {
        if (this.newPassword === this.confirmnewPassword) {
          const body = {
            account: this.$store.state.auth.user.account,
            newPassword: this.newPassword
          }
          const config = { params: { } }
          this.$utils
            .callAxiosWithBody(
              requests.SavePassword.method,
              url,
              body,
              config
            )
            .then(res => {
              console.log(res)
              this.dialog_passwordChange = false
              this.messageDialog = true
              this.messageText = res.data.responseMessage
              // EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
              // this.Logout()
            })
            .catch(error => {
              console.log(error.response.data.responseMessage)
              this.dialog_passwordChange = false
              EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
            })
        } else {
          EventBus.$emit('messageAlert', this.$t('The password confirmation does not match.'))
        }
      }
    },
    savePasswordwithoutpolicy () {
      const url = configs.ApiUrl + requests.SavePassword.url
      console.log(url)
      if (this.newPassword !== '' && this.confirmnewPassword !== '') {
        if (this.newPassword === this.confirmnewPassword) {
          this.savebutton = true
          const body = {
            account: this.$store.state.auth.user.account,
            newPassword: this.newPassword
          }
          const config = { params: { } }
          this.$utils
            .callAxiosWithBody(
              requests.SavePassword.method,
              url,
              body,
              config
            )
            .then(res => {
              console.log(res)
              this.dialog_passwordChange = false
              // EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
              this.Logout()
            })
            .catch(error => {
              console.log(error.response.data.responseMessage)
              // this.dialog_passwordChange = false
              EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
            })
        } else {
          EventBus.$emit('messageAlert', this.$t('The password confirmation does not match.'))
        }
      } else {
        EventBus.$emit('messageAlert', this.$t('Enter the Value.'))
      }
    },
    passwordValidate () {
      // Length 8 to 15
      if (this.newPassword.length > 7 && this.newPassword.length < 16 && this.newPassword.length !== 0) {
        this.has_length = true
      } else {
        this.has_length = false
      }
      // Upper Case
      this.has_uppercase = /[A-Z]/.test(this.newPassword)

      // Lower Case
      this.has_lowercase = /[a-z]/.test(this.newPassword)

      // One Number
      this.has_number = /\d/.test(this.newPassword)

      // Special Characters
      // eslint-disable-next-line
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.newPassword)
      // console.log(this.has_special)

      // Consective Characters
      this.has_consicutive = /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)/.test(this.newPassword)

      // Repeat
      this.has_repeat = /(.)\1\1/.test(this.newPassword)

      // Space
      this.has_space = /^\S+$/.test(this.newPassword)

      if (this.has_length && this.has_number && this.has_lowercase && this.has_uppercase && this.has_special && !this.has_consicutive && !this.has_repeat && this.has_space) {
        this.savebutton = true
      } else {
        this.savebutton = false
      }
    },
    afterUpdatePassword () {
      this.messageDialog = false
      this.Logout()
    },
    Logout () {
      const params = { account: this.$store.state.auth.user.account }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.logoutUser.method,
          requests.logoutUser.url,
          config
        )
        .finally(() => {
          this.$store
            .dispatch('auth/logout')
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.$store.dispatch('dataStore/CLEAN')
              this.$router.push('/login')
            })
        })
    },

    updateEmailAddress () {
      const url = configs.ApiUrl + requests.updateEmail.url
      console.log(url)
      if (this.updateEmail !== '') {
        const body = {
          account: this.$store.state.auth.user.account,
          emailAddress: this.updateEmail
        }
        const config = { params: { } }
        this.$utils
          .callAxiosWithBody(
            requests.SavePassword.method,
            url,
            body,
            config
          )
          .then(res => {
            console.log(res)
            this.emailDialog = false
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          })
          .catch(error => {
            console.log(error.response.data.responseMessage)
            EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
          })
      } else {
        EventBus.$emit('messageAlert', this.$t('Enter the Value.'))
      }
    },

    checkEmail (event) {
      console.log(event)
      this.checkedEmail = event
      console.log(this.checkedEmail)
    },
    updateEmailAddressLater () {
      this.emailDialog = false
      if (this.checkedEmail) {
        Cookies.set('EmailUpdateLater', this.checkedEmail)
        Cookies.set('accountName', this.$store.state.auth.user.account)
      }
    },

    getUserDetails () {
      const config = {
        params: { company: this.$store.state.auth.user.company, account: this.$store.state.auth.user.account }
      }
      this.$utils
        .callAxios(
          codes.requests.getUserDetails.method,
          codes.requests.getUserDetails.url,
          config
        )
        .then(res => {
          this.$store.commit('dataStore/updateemailAddress', res.data.emailAddress)
          this.$forceUpdate()
          const emailflag = Cookies.get('EmailUpdateLater')
          const accountName = Cookies.get('accountName')
          if (this.dialog_passwordChange !== true) {
            if (accountName === this.$store.state.auth.user.account && emailflag === 'true') {
              this.emailDialog = false
            } else if (this.$store.state.dataStore.emailaddress === '') {
              this.emailDialog = true
            }
            if (this.$store.state.dataStore.emailaddress !== '') {
              this.emailDialog = false
            }
          }
        }).catch(err => {
          console.log(err)
          this.$store.commit('dataStore/updateemailAddress', '')
          this.$forceUpdate()
          this.emailDialog = false
        })
    },
    // End Password and Email Update

    initOverview (store = {}) {
      if (commons.isValidData(store).result) {
        this.getSummarySearch(store)
      } else {
        this.getSummarySearch({
          company: this.$store.state.auth.user.company,
          country: store.country,
          region: store.region,
          city: store.city,
          code: store.code
        })
        // this.getSummarySearch({
        //   company: this.$store.state.auth.user.company,
        //   country: '',
        //   region: '',
        //   city: '',
        //   code: ''
        // })
      }
    },
    pushRoute (route) {
      this.$router.push(route).catch(error => {
        console.warn(`Failed to send user to original target page. error: ${error}`)
      })
    },
    translateHeaders (headers) {
      return headers.map(header => {
        header.text = this.$t(header.text)
        return header
      })
    },
    problemStoreVisible (problemType, val = 1) {
      const problem = {
        visible: Number(val) > 0,
        problemType: problemType
      }
      return problem
    },
    setProblemHeaderData (problemType) {
      if (problemType === 'gatewayOffline') {
        this.ProblemHeaders = [
          {
            text: '#',
            value: 'no',
            width: '10%',
            sortable: false,
            align: 'center'
          },
          { text: 'STORE NAME', value: 'storeName', sortable: false },
          {
            text: 'STORE CODE',
            width: '30%',
            value: 'storeCode',
            sortable: false
          },
          {
            text: 'Abnormal Gateway',
            width: '20%',
            value: 'count',
            sortable: false
          },
          { text: '', value: 'link', width: '10%', sortable: false }
        ]
      } else {
        this.ProblemHeaders = [
          {
            text: '#',
            value: 'no',
            width: '10%',
            sortable: false,
            align: 'center'
          },
          { text: 'STORE NAME', value: 'storeName', sortable: false },
          {
            text: 'STORE CODE',
            width: '30%',
            value: 'storeCode',
            sortable: false
          },
          {
            text: 'Abnormal Label',
            width: '20%',
            value: 'count',
            sortable: false
          },
          { text: '', value: 'link', width: '10%', sortable: false }
        ]
      }
    },
    showProblemPopup (problemType, route) {
      this.problemType = ''
      this.ProblemItems = []

      this.getProblemList({
        company: this.$store.state.auth.user.company,
        country: '',
        region: '',
        city: '',
        code: ''
      })
      this.problemType = problemType
      this.problemLink = route
      this.problemDialog = true
    },
    goDirectPage (menuCode, route, value, problem = { visible: false }) {
      if (this.serverType === 'cloud') {
        if (
          menuCode === '4100' || // Gateway Info
          menuCode === '8100' || // UserInfo
          menuCode === '8200' || // UserMapping
          menuCode === '8400' || // MyAccount
          menuCode === '9100'
        ) {
          // DashboardConfig
          route.path = route.path + 'Cloud'
        }
      }

      if (value === 0) return // 0이상인 경우만 실행, 그 외에는 중지.
      if (
        commons.notEmpty(this.$store.state.dataStore.selectedStore) && // Store 선택이 되어있고,
        this.$store.state.dataStore.selectedStore.code !== '' // Store 값이 비어있지 않다면.
      ) {
        this.$store.dispatch('auth/isAccessMenu', menuCode).then(flag => {
          if (flag && value > 0) this.$router.push(route) // 선택되어있는 Store로 이동.
        })
      } else {
        // Store가 선택되어 있지 않은 경우.
        if (problem.visible === true) {
          // Problem popup을 띄우는 항목이라면 Problem popup data 설정하고 띄움.
          this.setProblemHeaderData(problem.problemType)
          this.showProblemPopup(problem.problemType, route)
        } else {
          // Problem popup을 띄우는 항목이 아니라면 지정된 링크로 이동해서 Store 선택.
          this.$store.dispatch('auth/isAccessMenu', menuCode).then(flag => {
            if (flag && value > 0) this.$router.push(route)
          })
        }
      }
    },
    getPercent (subValue, momValue) {
      let per = 0
      if (momValue > 0) {
        per = ((subValue / momValue) * 100).toFixed(1) // 고정 소수점 0.1의자리까지 표기.
      }
      const fl = Math.floor(per)
      if ((per - fl) * 10 === 0) {
        per = fl
      }
      return per
    },
    buildParams (selectedStore) {
      const params = {
        account: this.$store.state.auth.user.account,
        aliveStatusEnabled: true,
        aliveInterval: this.$store.getters['auth/GET_INTERVAL'],
        company: commons.isValue(selectedStore.company),
        country: commons.isValue(selectedStore.country),
        region: commons.isValue(selectedStore.region),
        city: commons.isValue(selectedStore.city),
        store: commons.isValue(selectedStore.code)
      }
      if (this.serverType === 'cloud') {
        params.company = this.$store.state.auth.user.company
      }
      return params
    },
    getColorState (value, caution, danger) {
      let state = 'stable'
      if (Number(value) >= Number(caution)) state = 'caution'
      if (Number(value) >= Number(danger)) state = 'danger'
      return state
    },
    setAlertSetting (alertSetting) {
      if (this.$refs.length < 1) return
      // Label Status / Update Failure
      this.$refs.UpdateFailure.$el.classList.remove('stable')
      this.$refs.UpdateFailure.$el.classList.remove('caution')
      this.$refs.UpdateFailure.$el.classList.remove('danger')
      this.$refs.UpdateFailure.$el.classList.add(
        this.getColorState(
          this.percentUpdateFailure,
          alertSetting.labelUpdatedCaution,
          alertSetting.labelUpdatedDanger
        )
      )
      // Gateway Status / Gateway offline
      this.$refs.GatewayStatus.$el.classList.remove('stable')
      this.$refs.GatewayStatus.$el.classList.remove('caution')
      this.$refs.GatewayStatus.$el.classList.remove('danger')
      this.$refs.GatewayStatus.$el.classList.add(
        this.getColorState(
          this.getPercent(this.offlineGwCount, this.totalGwCount),
          alertSetting.gwOfflineCaution,
          alertSetting.gwOfflineDanger
        )
      )
      // Exception Status / Label Offline
      this.$refs.LabelOffline.$el.classList.remove('stable')
      this.$refs.LabelOffline.$el.classList.remove('caution')
      this.$refs.LabelOffline.$el.classList.remove('danger')
      this.$refs.LabelOffline.$el.classList.add(
        this.getColorState(
          this.getPercent(this.offlineLabelCount, this.totalUpdatedLabelCount),
          alertSetting.labelOfflineCaution,
          alertSetting.labelOfflineDanger
        )
      )
      // Exception Status / Low Battery
      this.$refs.LowBattery.$el.classList.remove('stable')
      this.$refs.LowBattery.$el.classList.remove('caution')
      this.$refs.LowBattery.$el.classList.remove('danger')
      this.$refs.LowBattery.$el.classList.add(
        this.getColorState(
          this.getPercent(this.lowBatteryCount, this.totalUpdatedLabelCount),
          alertSetting.labelLowBatteryCaution,
          alertSetting.labelLowBatteryDanger
        )
      )
      // Exception Status / Bad Signal
      this.$refs.BadSignal.$el.classList.remove('stable')
      this.$refs.BadSignal.$el.classList.remove('caution')
      this.$refs.BadSignal.$el.classList.remove('danger')
      this.$refs.BadSignal.$el.classList.add(
        this.getColorState(
          this.getPercent(this.badSignalLabelCount, this.totalUpdatedLabelCount),
          alertSetting.labelSignalCaution,
          alertSetting.labelSignalDanger
        )
      )
      // Exception Status / Misused
      this.$refs.Misused.$el.classList.remove('stable')
      this.$refs.Misused.$el.classList.remove('caution')
      this.$refs.Misused.$el.classList.remove('danger')
      this.$refs.Misused.$el.classList.add(
        this.getColorState(
          this.getPercent(this.misUsedLabelCount, this.totalUpdatedLabelCount),
          alertSetting.labelMisusedCaution,
          alertSetting.labelMisusedDanger
        )
      )
    },
    getAlertSetting () {
      const config = {}
      if (this.serverType === 'cloud') {
        config.params = { company: this.$store.state.auth.user.company }
      }
      this.$utils
        .callAxios(
          codes.requests.getAlertSetting.method,
          codes.requests.getAlertSetting.url,
          config
        )
        .then(res => {
          if (res.data) this.setAlertSetting(res.data)
        })
    },
    goProblemLink (problemStore) {
      problemStore = this.$store.state.dataStore.managedStores.find(
        store => store.code === problemStore.storeCode
      )
      const selectedStore = {
        company: problemStore.company,
        country: problemStore.country,
        region: problemStore.region,
        city: problemStore.city,
        code: problemStore.code,
        name: problemStore.name
      }
      if (this.productionType === codes.productionTypes.CLOUD) {
        this.$store.dispatch('auth/UPDATE_COMPANY', problemStore.company)
      }
      this.$store
        .dispatch('dataStore/UPDATE_SELECTED_STORE', selectedStore)
        .then(() => {
          this.pushRoute(this.problemLink)
        })
    },
    getProblemList (selectedStore) {
      if (selectedStore === undefined) {
        selectedStore = this.$store.state.dataStore.selectedStore
      }
      const params = this.buildParams(selectedStore)
      params.problemType = this.problemType
      params.size = 2000
      const config = { params }
      this.$utils
        .callAxios(
          codes.requests.getProblemAction.method,
          codes.requests.getProblemAction.url,
          config
        )
        .then(res => {
          if (res.data.problemStoreList === undefined) {
            throw new TypeError('Count not find any problem list.')
          }
          if (res.data.responseCode === '200') {
            this.ProblemItems = res.data.problemStoreList.map(
              (problemStore, index) => {
                problemStore.no = index + 1
                return problemStore
              }
            )
          } else {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          }
        })
        .catch(error => {
          console.debug(`Failed to get problematic store list. Error: ${error}`)
        })
    },
    getSummarySearch (selectedStore) {
      const params = this.buildParams(selectedStore)
      const config = { params }
      config.params.isLabelAlive = configs.isLabelAlive
      this.$utils
        .callAxios(
          codes.requests.getSummaryAction.method,
          codes.requests.getSummaryAction.url,
          config
        )
        .then(res => {
          if (res.data.responseCode === '200') {
            this.setSummary(res.data)
          } else {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          }
        })
        .catch(error => {
          console.debug(`Failed to load store summary. error: ${error}`)
        })
    },
    setSummary (data) {
      console.log(data)
      var datasplit = data.lastModified.split(',')
      this.LastModifiedDate = datasplit[0]
      this.LastTimezone = datasplit[1]
      this.lastUpdateTime = data.lastUpdateTime
      // ##### Label Status #####
      this.updatedLabelCount = data.updatedLabelCount
      this.totalUpdatedLabelCount = data.totalUpdatedLabelCount
      this.percentUpdateSuccess = this.getPercent(
        data.updatedLabelCount,
        data.totalUpdatedLabelCount
      )
      this.inProgressLabelCount = data.inProgressLabelCount
      this.percentProcessing = this.getPercent(
        data.inProgressLabelCount,
        data.totalUpdatedLabelCount
      )
      this.notUpdatedLabelCount = data.notUpdatedLabelCount
      this.percentUpdateFailure = this.getPercent(
        data.notUpdatedLabelCount,
        data.totalUpdatedLabelCount
      )
      this.totalProductCount = data.totalProductCount
      this.totalAssignedProductCount = data.totalAssignedProductCount
      this.totalLabelCount = data.totalLabelCount
      // ##### Exception Status #####
      if (data.offlineLabelCount === -1) {
        this.offlineLabelStatus = 'N/A'
        this.offlineLabelCount = 0
      } else {
        this.offlineLabelStatus = data.offlineLabelCount
        this.offlineLabelCount = data.offlineLabelCount
      }

      this.lowBatteryCount = data.lowBatteryCount
      this.veryGoodBatteryCount = data.veryGoodBatteryCount
      this.goodBatteryCount = data.goodBatteryCount
      this.replaceBatteryCount = data.replaceBatteryCount
      this.badSignalLabelCount = data.badSignalLabelCount
      this.misUsedLabelCount = data.misUsedLabelCount
      // ##### GateWay #####
      this.offlineGwCount = data.offlineGwCount
      this.totalGwCount =
        data.offlineGwCount + data.notReadyGwCount + data.onlineGwCount
      // ##### Progress bar #####
      if (data.lastUpdateInprogressCount === 0) {
        this.progressBarShow = 'none'
      } else {
        this.progressBarShow = 'block'
      }
      this.lastUpdateInprogressCount = data.lastUpdateInprogressCount
      this.lastUpdatetotalCount = data.lastUpdatetotalCount
      this.percentProgress = this.getPercent(
        this.lastUpdateInprogressCount,
        this.lastUpdatetotalCount
      )
      // ##### Chart #####
      this.mixChartActive = true
      this.updateStatus = data.updateStatus
      // color setting
      this.getAlertSetting()
    },

    // Rogers
    openFileuploadPopup () {
      this.Fileuploadpopup = true
      this.filesToUpload = []
      this.filesToUploadLater = []
    },
    openMessageboardPopup () {
      if (this.emptymesssge === true) {
        this.messagetextdata = ''
      }
      this.MessageBoardpopup = true
    },
    setCurrentDate (e) {
      if (!this.scheduleTime) {
        console.log(e)
        setTimeout(() => {
          this.scheduleTime = moment(new Date()).format('YYYY-MM-DD HH:00')
        }, 200)
      }
    },
    appendFilesLater (e) {
      console.log(e[0])
      this.filesToUploadLater = [...this.filesToUploadLater, ...this.filesLater]
      this.filesLater = []
    },
    removeFileLater (fileName) {
      this.filesToUploadLater = this.filesToUploadLater.filter(
        (file) => file.name !== fileName
      )
    },
    getBase64withFormat (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const result = reader.result.split(',')[1]
          resolve(result)
        }
        reader.onerror = (error) => reject(error)
      })
    },
    async uploafiles (value) {
      console.log(value)
      // ?relativepath=bin/converter/input
      var count = 0
      for (const fileforbase64 of this.filesToUploadLater) {
        console.log(this.fileforbase64)
        count++
        const contentsBase64 = await this.getBase64withFormat(fileforbase64)
        const body = {
          contentType: 'IMAGE',
          pageIndex: count,
          imgBase64: contentsBase64,
          fileName: fileforbase64.name
        }
        this.finalimagearray.push(body)
        var fname = body.fileName
        var re = /(\.jpg|\.jpeg|\.bmp|\.png|\.xlsm)$/i
        if (!re.exec(fname)) {
          EventBus.$emit(
            'messageAlert',
            this.$t('This extension is not supported.')
          )
          this.finalimagearray = []
          return
        }
      }
      var data = { contents: this.finalimagearray }
      var params = { }
      if (value === 'now') {
        params.updateNow = true
      } else {
        params.updateNow = false
      }

      const config = { params: params }
      const url = configs.ServerAddress + requests.touploadfiles.url
      return this.$utils
        .callAxiosWithBody(
          requests.touploadfiles.method,
          url,
          data,
          config
        )
        .then(res => {
          const result = res.data
          console.log(result)
          if (res.status === 200) {
            // if (value === 'now') {
            //   this.touploadfilepath()
            //   this.createlog(result)
            // } else {
            //   this.createlog(result)
            // }
            EventBus.$emit('messageAlert', this.$t('File Uploaded successfully!'))
            this.filesToUpload = []
            this.filesToUploadLater = []
          }
        })
        .catch(error => {
          console.log(error)
          EventBus.$emit('messageAlert', this.$t('Failed to Upload.'))
          this.filesToUpload = []
          this.filesToUploadLater = []
        })
    },

    touploadfilepath () {
      const params = { relativepath: 'bin/converter/input/updateNow.sh' }
      const config = { params: params }
      // const url = configs.ServerAddress + requests.uploadFilepath.url
      this.$utils
        .callAxios(
          requests.uploadFilepath.method,
          requests.uploadFilepath.url,
          config
        )
        .then((res) => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t('File Uploaded successfully!'))
            this.filesToUpload = []
            this.filesToUploadLater = []
          }
        })
        .catch(error => {
          console.log(error)
          EventBus.$emit('messageAlert', this.$t('Failed to Upload.'))
          this.filesToUpload = []
          this.filesToUploadLater = []
        })
    },
    createlog (data) {
      console.log(data)
      var LogJson = {}
      LogJson.type = 9
      LogJson.userName = this.$store.state.auth.user.account
      LogJson.url = 'File Upload'
      LogJson.jsonData = data.join(',')
      LogJson.result = 200
      console.log(LogJson)
      const params = { }
      const config = { params: params }
      const url = configs.ServerAddress + requests.tocreatelog.url
      return this.$utils
        .callAxiosWithBody(
          requests.tocreatelog.method,
          url,
          LogJson,
          config
        )
        .then(res => {
          const result = res.data
          console.log(result)
          EventBus.$emit('messageAlert', this.$t('Saved Successfully'))
        })
        .catch(error => {
          console.log(error)
          EventBus.$emit('messageAlert', this.$t('Failed to save.'))
        })
    },
    getmessageBoard () {
      const params = {}
      const config = { params: params }
      // const url = configs.ServerAddress + requests.getmessageBoard.url
      this.$utils
        .callAxios(
          requests.getmessageBoard.method,
          requests.getmessageBoard.url,
          config
        )
        .then((res) => {
          console.log(res)

          // const datalength = res.data.length
          // this.messagetextdata = res.data[datalength - 1].item
          if (res.status === 200) {
            this.emptymesssge = false
            this.messagetextdata = res.data.message
          } else {
            this.messagetextdata = 'Kindly upload announcement !'
            this.emptymesssge = true
          }
          if (res.data.message === '') {
            this.messagetextdata = 'Kindly upload announcement !'
            this.emptymesssge = true
          }
        })
    },
    producttype (eve) {
      this.producttypechange = true
      console.log('Data', eve)
      this.getvaluebyproducttype(eve)
    },

    getvaluebyproducttype (val) {
      console.log(this.productdataselect)
      console.log(configs.ServerAddress)
      // key=PRODUCT_TYPE&stationCode=RC001&value=Accessory&size=2000
      const config = {}
      config.params = { key: 'PRODUCT_TYPE', stationCode: this.selectedStore.code, value: val, size: 2000 }
      const url = configs.ServerAddress + requests.getvaluebyproducttype.url
      this.$utils
        .callAxios(
          requests.getvaluebyproducttype.method,
          url,
          config
        )
        .then(res => {
          console.log(res.data)
          if (res.status === 200) {
            this.productlist = res.data
          } else {
            this.productlist = []
          }
        }).catch((err) => {
          EventBus.$emit('messageAlert', this.$t(err.response.data.responseMessage))
        })
    },

    messagetextdataSave () {
      console.log(this.messagetextdata)
      // const setting = {
      //   category: 'dashboardSettings',
      //   item: this.messagetextdata,
      //   param1: 'param1',
      //   param3: 'param3',
      //   param4: 'param4',
      //   param5: 'param5',
      //   param6: 'param6',
      //   param7: 'param7',
      //   param8: 'param8',
      //   param9: 'param9',
      //   param10: 'param10',
      //   paramDescr: 'paramDescr',
      //   value: 'value'
      // }
      const setting = {
        message: this.messagetextdata
      }
      const params = { }
      const config = { params: params }
      // const url = configs.ServerAddress + requests.postmessageBoard.url
      return this.$utils
        .callAxiosWithBody(
          requests.postmessageBoard.method,
          requests.postmessageBoard.url,
          setting,
          config
        )
        .then(res => {
          const result = res.data
          console.log(result)
          EventBus.$emit('messageAlert', this.$t('Saved Successfully'))
          this.emptymesssge = false
          this.getmessageBoard()
        })
        .catch(error => {
          console.log(error)
          EventBus.$emit('messageAlert', this.$t('Failed to save.'))
          this.emptymesssge = false
          this.getmessageBoard()
        })
    },
    cancelmessageboard () {
      this.emptymesssge = false
      this.getmessageBoard()
    },

    // Rogers end
    toPreSelectStoreforSingleServer () {
      const data = this.$store.state.dataStore.managedStores
      console.log(data)
      const selectedStore = {
        company: data[0].company,
        country: data[0].country,
        region: data[0].region,
        city: data[0].city,
        code: data[0].code,
        name: data[0].name
      }
      this.$store.dispatch('dataStore/UPDATE_SELECTED_STORE', selectedStore)
    }
  }
}
</script>
<!-- //Rogers -->
<style scoped>
::v-deep .form-select.v-text-field--outlined .v-select__selection--comma {
line-height: 32px !important;
}
::v-deep .v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
    font-size: 14px !important;
}
::v-deep .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    font-size: 14px !important;
}

</style>
<!-- Rogers end -->
<style scoped>
.iconClassNot{
  color: lightgrey !important;
}
.iconClass{
  color: black !important;
}
.Offlinedot {
  height: 19px;
  width: 19px;
  background-color: rgb(247, 7, 7);
  border-radius: 50%;
  display: inline-block;
}
.Onlinedot {
  height: 19px;
  width: 19px;
  background-color: rgb(81, 247, 4);
  border-radius: 50%;
  display: inline-block;
}
.LabelStatusborder{
    float: right;
    right: 0;
    top: 106px;
    height: 20px;
    position: absolute;
    border-width: 2px !important;
}

/* //Rogers */
.btnclass{
  float: left;
}
.fileuploadbtn{
  color: #fff !important;
    line-height: 34px;
    font-size: var(--font17);
    border: 1px solid #7c7b8e;
    background: #001e38 !important;
    border-radius: 5px;
    letter-spacing: -0.005em;
    width: 150px;

}
.anouncementclass{
  height:84px;
  border:solid 1px #cbcbcb;
  float: right;
  border-radius: 3px;
  overflow-y: auto;
  font-size: var(--font16);
  color: #001e38
}
/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c0bdbd;
  border-radius: 5px;
}
/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.messageboardClass{
    height: 250px;
    border: solid 1px #cbcbcb;
    border-radius: 3px;
    font-size: var(--font16);
    color: #001e38;
    padding:10px;
}
.searchIcon{
    margin-right: -12px;
    display: inline-block;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    cursor: pointer;
  }
  .selectedFile-list {
  background-color: #fff;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
/* //Rogers */

</style>
