var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("v-row", [
        _c("div", [
          _c("h2", { staticClass: "page-title-bar" }, [
            _c("i", { staticClass: "ico ico-overview" }),
            _vm._v(_vm._s(_vm.$t("Overview")) + " ")
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "label-txt",
            staticStyle: { "margin-left": "10px", "margin-top": "10px" }
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.LastModifiedDate))]),
            _c("span", { staticStyle: { "margin-left": "5px" } }, [
              _vm._v("(" + _vm._s(_vm.LastTimezone) + ")")
            ])
          ]
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "state-info" }, [
          _c("ul", [
            _c("li", { staticClass: "state-danger" }, [
              _vm._v(_vm._s(_vm.$t("DANGER")))
            ]),
            _c("li", { staticClass: "state-caution" }, [
              _vm._v(_vm._s(_vm.$t("CAUTION")))
            ]),
            _c("li", { staticClass: "state-stable" }, [
              _vm._v(_vm._s(_vm.$t("STABLE")))
            ])
          ])
        ]),
        _c("div", { staticClass: "main-layout layout01" }, [
          _c("div", { staticClass: "main-section" }, [
            _c("h3", { staticClass: "tit-section" }, [
              _vm._v(_vm._s(_vm.$t("Label Status")))
            ]),
            _c(
              "div",
              { staticClass: "group" },
              [
                _c(
                  "v-btn",
                  { staticClass: "stateBox" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: {} },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.goDirectPage(
                              "2100",
                              {
                                name: "Label Info",
                                params: { status: "SUCCESS" }
                              },
                              _vm.updatedLabelCount
                            )
                          }
                        }
                      },
                      [
                        _c("dl", [
                          _vm.CUSTOMER !== "METRO"
                            ? _c("dt", [
                                _vm._v(_vm._s(_vm.$t("Update Success")))
                              ])
                            : _vm._e(),
                          _vm.CUSTOMER === "METRO"
                            ? _c(
                                "dt",
                                {
                                  staticStyle: {
                                    "border-color": "rgb(100, 184, 106)"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("Labels OK")))]
                              )
                            : _vm._e(),
                          _c("dd", [
                            _c("p", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.percentUpdateSuccess) + "%")
                              ])
                            ]),
                            _vm.CUSTOMER !== "METRO"
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.updatedLabelCount
                                        )
                                      ) +
                                      "ea / " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalUpdatedLabelCount
                                        )
                                      ) +
                                      "ea "
                                  )
                                ])
                              : _vm._e(),
                            _vm.CUSTOMER === "METRO"
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.updatedLabelCount
                                        )
                                      ) +
                                      " / " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalUpdatedLabelCount
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm.CUSTOMER === "METRO"
                            ? _c("span", {
                                staticClass: "LabelStatusborder",
                                staticStyle: {
                                  border: "solid rgb(100, 184, 106)"
                                }
                              })
                            : _vm._e()
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  { staticClass: "stateBox" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: {} },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.goDirectPage(
                              "2100",
                              {
                                name: "Label Info",
                                params: { status: "PROCESSING" }
                              },
                              _vm.inProgressLabelCount
                            )
                          }
                        }
                      },
                      [
                        _c("dl", [
                          _vm.CUSTOMER !== "METRO"
                            ? _c("dt", [_vm._v(_vm._s(_vm.$t("Processing")))])
                            : _vm._e(),
                          _vm.CUSTOMER === "METRO"
                            ? _c(
                                "dt",
                                {
                                  staticStyle: {
                                    "border-color": "rgb(100, 184, 106)"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("In Update")))]
                              )
                            : _vm._e(),
                          _c("dd", [
                            _c("p", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.percentProcessing) + "%")
                              ])
                            ]),
                            _vm.CUSTOMER !== "METRO"
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.inProgressLabelCount
                                        )
                                      ) +
                                      "ea / " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalUpdatedLabelCount
                                        )
                                      ) +
                                      "ea "
                                  )
                                ])
                              : _vm._e(),
                            _vm.CUSTOMER === "METRO"
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.inProgressLabelCount
                                        )
                                      ) +
                                      "/ " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalUpdatedLabelCount
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm.CUSTOMER === "METRO"
                            ? _c("span", {
                                staticClass: "LabelStatusborder",
                                staticStyle: {
                                  border: "solid rgb(100, 184, 106)"
                                }
                              })
                            : _vm._e()
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  { ref: "UpdateFailure", staticClass: "stateBox" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: {} },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.goDirectPage(
                              "2100",
                              {
                                name: "Label Info",
                                params: { status: "TIMEOUT" }
                              },
                              _vm.notUpdatedLabelCount,
                              _vm.problemStoreVisible("updateFailure")
                            )
                          }
                        }
                      },
                      [
                        _c("dl", [
                          _vm.CUSTOMER !== "METRO"
                            ? _c("dt", [
                                _vm._v(_vm._s(_vm.$t("Update Failure")))
                              ])
                            : _vm._e(),
                          _vm.CUSTOMER === "METRO"
                            ? _c("dt", [
                                _vm._v(_vm._s(_vm.$t("Labels Not OK")))
                              ])
                            : _vm._e(),
                          _c("dd", [
                            _c("p", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.percentUpdateFailure) + "%")
                              ])
                            ]),
                            _vm.CUSTOMER !== "METRO"
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.notUpdatedLabelCount
                                        )
                                      ) +
                                      "ea / " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalUpdatedLabelCount
                                        )
                                      ) +
                                      "ea "
                                  )
                                ])
                              : _vm._e(),
                            _vm.CUSTOMER === "METRO"
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.notUpdatedLabelCount
                                        )
                                      ) +
                                      " / " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalUpdatedLabelCount
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm.CUSTOMER === "METRO"
                            ? _c("span", {
                                staticClass: "LabelStatusborder",
                                staticStyle: {
                                  border: "solid rgb(226, 112, 112)"
                                }
                              })
                            : _vm._e()
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  { staticClass: "stateBox" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: {} },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.goDirectPage(
                              "3100",
                              { name: "Product Info" },
                              _vm.totalProductCount
                            )
                          }
                        }
                      },
                      [
                        _c("dt", [_vm._v(_vm._s(_vm.$t("Total Product")))]),
                        _c("dd", [
                          _c("p", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(_vm.totalProductCount)
                                )
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "dl",
                  {
                    staticClass: "stateBox",
                    staticStyle: { "margin-left": "10px" }
                  },
                  [
                    _c("dt", [
                      _vm._v(_vm._s(_vm.$t("Total Assigned Product")))
                    ]),
                    _c("dd", [
                      _c("p", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.totalAssignedProductCount)
                            )
                          )
                        ])
                      ])
                    ])
                  ]
                ),
                _vm.CUSTOMER !== "METRO"
                  ? _c(
                      "v-btn",
                      { staticClass: "stateBox ml_10" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: {} },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.goDirectPage(
                                  "2100",
                                  {
                                    name: "Label Info",
                                    params: { status: _vm.StatusParam }
                                  },
                                  _vm.totalLabelCount
                                )
                              }
                            }
                          },
                          [
                            _c("dl", [
                              _c("dt", [_vm._v(_vm._s(_vm.$t("Total Label")))]),
                              _c("dd", [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalUpdatedLabelCount
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.CUSTOMER === "METRO"
                  ? _c(
                      "v-btn",
                      { staticClass: "stateBox ml_10" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: {} },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.goDirectPage(
                                  "2100",
                                  {
                                    name: "Label Info",
                                    params: { status: _vm.StatusParam }
                                  },
                                  _vm.totalLabelCount
                                )
                              }
                            }
                          },
                          [
                            _c("dl", [
                              _c("dt", [_vm._v(_vm._s(_vm.$t("Total Label")))]),
                              _c("dd", [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.totalUpdatedLabelCount
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm.CUSTOMER === "LCL" || _vm.CUSTOMER === "METRO"
            ? _c(
                "div",
                { staticClass: "main-section" },
                [
                  _c("h3", { staticClass: "tit-section" }, [
                    _vm._v(_vm._s(_vm.$t("Exception Status")))
                  ]),
                  _c(
                    "v-btn",
                    {
                      ref: "LowBattery",
                      staticClass: "stateBox",
                      staticStyle: { "margin-bottom": "20px", width: "100%" }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: {} },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.goDirectPage(
                                "2100",
                                {
                                  name: "Label Info",
                                  params: {
                                    battery: "VERYGOOD",
                                    status: _vm.StatusParam
                                  }
                                },
                                _vm.veryGoodBatteryCount,
                                _vm.problemStoreVisible("verygoodBattery")
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "dt",
                            {
                              staticStyle: {
                                "border-bottom": "1px solid #197FE5"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Battery")))]
                          ),
                          _c(
                            "dd",
                            { staticStyle: { width: "25%", float: "right" } },
                            [
                              _c("p", [
                                _c(
                                  "strong",
                                  { staticStyle: { color: "#197FE5" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.veryGoodBatteryCount
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _vm.CUSTOMER !== "METRO"
                                ? _c("p", [_vm._v(" 50~100% ")])
                                : _vm._e(),
                              _vm.CUSTOMER === "METRO"
                                ? _c(
                                    "p",
                                    { staticStyle: { color: "#197FE5" } },
                                    [_vm._v(" VERYGOOD ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: { to: {} },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.goDirectPage(
                                "2100",
                                {
                                  name: "Label Info",
                                  params: {
                                    battery: "GOOD",
                                    status: _vm.StatusParam
                                  }
                                },
                                _vm.goodBatteryCount,
                                _vm.problemStoreVisible("goodBattery")
                              )
                            }
                          }
                        },
                        [
                          _c("dt", {
                            staticStyle: {
                              "border-bottom": "1px solid #64B86A"
                            }
                          }),
                          _c(
                            "dd",
                            { staticStyle: { width: "25%", float: "right" } },
                            [
                              _c("p", [
                                _c(
                                  "strong",
                                  { staticStyle: { color: "#64B86A" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(_vm.goodBatteryCount)
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _vm.CUSTOMER !== "METRO"
                                ? _c("p", [_vm._v(" 25~49% ")])
                                : _vm._e(),
                              _vm.CUSTOMER === "METRO"
                                ? _c(
                                    "p",
                                    { staticStyle: { color: "#64B86A" } },
                                    [_vm._v(" GOOD ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: { to: {} },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.goDirectPage(
                                "2100",
                                {
                                  name: "Label Info",
                                  params: {
                                    battery: "LOW",
                                    status: _vm.StatusParam
                                  }
                                },
                                _vm.lowBatteryCount,
                                _vm.problemStoreVisible("lowBattery")
                              )
                            }
                          }
                        },
                        [
                          _c("dt", {
                            staticStyle: {
                              "border-bottom": "1px solid #F5B45F"
                            }
                          }),
                          _c(
                            "dd",
                            { staticStyle: { width: "25%", float: "right" } },
                            [
                              _c("p", [
                                _c(
                                  "strong",
                                  { staticStyle: { color: "#F5B45F" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(_vm.lowBatteryCount)
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _vm.CUSTOMER !== "METRO"
                                ? _c("p", [_vm._v(" 10~24% ")])
                                : _vm._e(),
                              _vm.CUSTOMER === "METRO"
                                ? _c(
                                    "p",
                                    { staticStyle: { color: "#F5B45F" } },
                                    [_vm._v(" LOW ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: { to: {} },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.goDirectPage(
                                "2100",
                                {
                                  name: "Label Info",
                                  params: {
                                    battery: "REPLACE",
                                    status: _vm.StatusParam
                                  }
                                },
                                _vm.replaceBatteryCount,
                                _vm.problemStoreVisible("replaceBattery")
                              )
                            }
                          }
                        },
                        [
                          _c("dt", {
                            staticStyle: {
                              "border-bottom": "1px solid #E27070"
                            }
                          }),
                          _c(
                            "dd",
                            { staticStyle: { width: "25%", float: "right" } },
                            [
                              _c("p", [
                                _c(
                                  "strong",
                                  { staticStyle: { color: "#E27070" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.replaceBatteryCount
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _vm.CUSTOMER !== "METRO"
                                ? _c("p", [_vm._v(" 0~9% ")])
                                : _vm._e(),
                              _vm.CUSTOMER === "METRO"
                                ? _c(
                                    "p",
                                    { staticStyle: { color: "#E27070" } },
                                    [_vm._v(" REPLACE ")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "div",
                    { staticClass: "group", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-0 ma-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              ref: "LabelOffline",
                              staticClass: "stateBox col"
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: {} },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.goDirectPage(
                                        "2100",
                                        {
                                          name: "Label Info",
                                          params: {
                                            network: "false",
                                            status: _vm.StatusParam
                                          }
                                        },
                                        _vm.offlineLabelCount,
                                        _vm.problemStoreVisible("labelOffline")
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("dt", [_vm._v(_vm._s(_vm.$t("Offline")))]),
                                  _c("dd", [
                                    _c("p", [
                                      _vm.offlineLabelStatus !== "N/A"
                                        ? _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.offlineLabelStatus
                                                )
                                              )
                                            )
                                          ])
                                        : _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.offlineLabelStatus)
                                            )
                                          ])
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            { ref: "BadSignal", staticClass: "stateBox col" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: {} },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.goDirectPage(
                                        "2100",
                                        {
                                          name: "Label Info",
                                          params: {
                                            signal: "BAD",
                                            status: _vm.StatusParam
                                          }
                                        },
                                        _vm.badSignalLabelCount,
                                        _vm.problemStoreVisible("badSignal")
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("dt", [
                                    _vm._v(_vm._s(_vm.$t("Bad RF quality")))
                                  ]),
                                  _c("dd", [
                                    _c("p", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.badSignalLabelCount
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              ref: "Misused",
                              staticClass: "stateBox col",
                              staticStyle: { "margin-left": "10px" }
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: {} },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.goDirectPage(
                                        "2100",
                                        {
                                          name: "Label Info",
                                          params: {
                                            misusedLabelType: "All",
                                            status: _vm.StatusParam
                                          }
                                        },
                                        _vm.misUsedLabelCount,
                                        _vm.problemStoreVisible("misused")
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("dt", [_vm._v(_vm._s(_vm.$t("Misused")))]),
                                  _c("dd", [
                                    _c("p", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.misUsedLabelCount
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.CUSTOMER !== "LCL" && _vm.CUSTOMER !== "METRO"
            ? _c("div", { staticClass: "main-section" }, [
                _c("h3", { staticClass: "tit-section" }, [
                  _vm._v(_vm._s(_vm.$t("Exception Status")))
                ]),
                _c(
                  "div",
                  { staticClass: "group" },
                  [
                    _c(
                      "v-btn",
                      { ref: "LabelOffline", staticClass: "stateBox" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: {} },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.goDirectPage(
                                  "2100",
                                  {
                                    name: "Label Info",
                                    params: {
                                      network: "false",
                                      status: _vm.StatusParam
                                    }
                                  },
                                  _vm.offlineLabelCount,
                                  _vm.problemStoreVisible("labelOffline")
                                )
                              }
                            }
                          },
                          [
                            _c("dt", [_vm._v(_vm._s(_vm.$t("Label Offline")))]),
                            _c("dd", [
                              _c("p", [
                                _vm.offlineLabelStatus !== "N/A"
                                  ? _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.offlineLabelStatus
                                          )
                                        )
                                      )
                                    ])
                                  : _c("strong", [
                                      _vm._v(_vm._s(_vm.offlineLabelStatus))
                                    ])
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      { ref: "LowBattery", staticClass: "stateBox" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: {} },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.goDirectPage(
                                  "2100",
                                  {
                                    name: "Label Info",
                                    params: {
                                      battery: "BAD",
                                      status: _vm.StatusParam
                                    }
                                  },
                                  _vm.lowBatteryCount,
                                  _vm.problemStoreVisible("lowBattery")
                                )
                              }
                            }
                          },
                          [
                            _c("dt", [_vm._v(_vm._s(_vm.$t("Low Battery")))]),
                            _c("dd", [
                              _c("p", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(_vm.lowBatteryCount)
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      { ref: "BadSignal", staticClass: "stateBox" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: {} },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.goDirectPage(
                                  "2100",
                                  {
                                    name: "Label Info",
                                    params: {
                                      signal: "BAD",
                                      status: _vm.StatusParam
                                    }
                                  },
                                  _vm.badSignalLabelCount,
                                  _vm.problemStoreVisible("badSignal")
                                )
                              }
                            }
                          },
                          [
                            _c("dt", [_vm._v(_vm._s(_vm.$t("Bad Signal")))]),
                            _c("dd", [
                              _c("p", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.badSignalLabelCount
                                      )
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      { ref: "Misused", staticClass: "stateBox" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: {} },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.goDirectPage(
                                  "2100",
                                  {
                                    name: "Label Info",
                                    params: {
                                      misusedLabelType: "All",
                                      status: _vm.StatusParam
                                    }
                                  },
                                  _vm.misUsedLabelCount,
                                  _vm.problemStoreVisible("misused")
                                )
                              }
                            }
                          },
                          [
                            _c("dt", [_vm._v(_vm._s(_vm.$t("Misused")))]),
                            _c("dd", [
                              _c("p", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(_vm.misUsedLabelCount)
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "main-layout layout02" }, [
          _c("div", { staticClass: "main-section" }, [
            _vm.CUSTOMER === "METRO"
              ? _c("div", [
                  _vm.totalGwCount === 0
                    ? _c("div", [
                        _c("h3", { staticClass: "tit-section" }, [
                          _vm._v(_vm._s(_vm.$t("Gateway Status")))
                        ])
                      ])
                    : _vm._e(),
                  _vm.offlineGwCount === 0 && _vm.totalGwCount !== 0
                    ? _c("div", [
                        _c(
                          "h3",
                          {
                            staticClass: "tit-section",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _vm._v(_vm._s(_vm.$t("Gateway Status"))),
                            _c("span", { staticClass: "Onlinedot" })
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.offlineGwCount !== 0
                    ? _c("div", [
                        _c(
                          "h3",
                          {
                            staticClass: "tit-section",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _vm._v(_vm._s(_vm.$t("Gateway Status"))),
                            _c("span", { staticClass: "Offlinedot" })
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.CUSTOMER !== "METRO"
              ? _c("h3", { staticClass: "tit-section" }, [
                  _vm._v(_vm._s(_vm.$t("Gateway Status")))
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "group" },
              [
                _c("v-btn", { ref: "GatewayStatus", staticClass: "stateBox" }, [
                  _c(
                    "dl",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: {} },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.goDirectPage(
                                "4100",
                                {
                                  name: "Gateway Info",
                                  params: { network: "offline" }
                                },
                                _vm.offlineGwCount,
                                _vm.problemStoreVisible("gatewayOffline")
                              )
                            }
                          }
                        },
                        [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Gateway Offline")))]),
                          _c("dd", [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm._f("currency")(_vm.offlineGwCount))
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("v-btn", { staticClass: "stateBox" }, [
                  _c(
                    "dl",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: {} },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.goDirectPage(
                                "4100",
                                {
                                  name: "Gateway Info",
                                  params: { network: "all" }
                                },
                                _vm.totalGwCount
                              )
                            }
                          }
                        },
                        [
                          _c("dt", [_vm._v(_vm._s(_vm.$t("Total Gateway")))]),
                          _c("dd", [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm._f("currency")(_vm.totalGwCount))
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "main-section" }, [
            _c("h3", { staticClass: "tit-section" }, [
              _vm._v(_vm._s(_vm.$t("Update Status")))
            ]),
            _c(
              "div",
              {
                staticClass: "graphBox",
                style: { display: _vm.progressBarShow },
                attrs: { id: "progress-linear" }
              },
              [
                _c("h4", { staticClass: "tit-sub" }, [
                  _vm._v(_vm._s(_vm.$t("Latest Update Status")))
                ]),
                _c(
                  "div",
                  { staticClass: "lineBar" },
                  [
                    _c("v-slider", {
                      staticClass: "linear-graph",
                      attrs: {
                        readonly: "",
                        dense: "",
                        "hide-details": "",
                        "track-color": "#ffffff"
                      },
                      model: {
                        value: _vm.percentProgress,
                        callback: function($$v) {
                          _vm.percentProgress = $$v
                        },
                        expression: "percentProgress"
                      }
                    }),
                    _c("div", { staticClass: "bar_data" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.percentProgress) + "%")]),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.lastUpdateInprogressCount)
                            ) +
                            "ea / " +
                            _vm._s(
                              _vm._f("currency")(_vm.lastUpdatetotalCount)
                            ) +
                            "ea "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "graphBox" },
              [
                _vm.CUSTOMER !== "METRO"
                  ? _c("h4", { staticClass: "tit-sub" }, [
                      _vm._v(_vm._s(_vm.$t("Total Update Order")))
                    ])
                  : _vm._e(),
                _vm.CUSTOMER === "METRO"
                  ? _c("h4", { staticClass: "tit-sub" }, [
                      _vm._v(_vm._s(_vm.$t("Total Update")))
                    ])
                  : _vm._e(),
                _c("mixChart", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.progressBarShow === "none",
                      expression: "progressBarShow === 'none'"
                    }
                  ],
                  attrs: { height: 250, updateStatus: _vm.updateStatus }
                }),
                _c("mixChart", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.progressBarShow === "block",
                      expression: "progressBarShow === 'block'"
                    }
                  ],
                  attrs: { height: 160, updateStatus: _vm.updateStatus }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm.CUSTOMER === "ROGERS"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              !_vm.storeselected
                ? _c(
                    "div",
                    { staticClass: "col-2 btnclass" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "col-12 fileuploadbtn",
                          on: { click: _vm.openFileuploadPopup }
                        },
                        [_vm._v("File Upload")]
                      ),
                      _c("br"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "col-12 mt-3 fileuploadbtn",
                          on: { click: _vm.openMessageboardPopup }
                        },
                        [_vm._v("Message Board")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.storeselected
                ? _c("div", { staticClass: "col-2 btnclass" })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "col-10 mt-3 anouncementclass custom-scrollbar"
                },
                [
                  _c("pre", [
                    _vm._v(
                      "          " + _vm._s(_vm.messagetextdata) + "\n        "
                    )
                  ])
                ]
              ),
              _c("br", { attrs: { clear: "“all”" } })
            ])
          ])
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "500" },
          model: {
            value: _vm.Fileuploadpopup,
            callback: function($$v) {
              _vm.Fileuploadpopup = $$v
            },
            expression: "Fileuploadpopup"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "page-title-bar mb-2" }, [
                  _c(
                    "i",
                    {
                      staticClass: "ico",
                      staticStyle: { "margin-right": "3px" }
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "33",
                          height: "32",
                          src: require("@/assets/img/ico-upload-file.gif")
                        }
                      })
                    ]
                  ),
                  _vm._v(_vm._s(_vm.$t("File Upload")) + " ")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("p", { staticClass: "txt-s mb-2" }, [
                            _vm._v(
                              "Select File (Extension :.xlsm) *View file format"
                            )
                          ]),
                          _c("v-file-input", {
                            staticClass: "form-file",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "prepend-icon": "",
                              solo: "",
                              flat: "",
                              "persistent-hint": "",
                              placeholder: "" + _vm.$t("Select file"),
                              multiple: "true",
                              "append-icon": "mdi-file-upload"
                            },
                            on: {
                              change: function($event) {
                                return _vm.appendFilesLater($event)
                              }
                            },
                            model: {
                              value: _vm.filesLater,
                              callback: function($$v) {
                                _vm.filesLater = $$v
                              },
                              expression: "filesLater"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "label-txt" }, [
                          _vm._v(_vm._s(_vm.$t("Selected Files")))
                        ]),
                        _c(
                          "ul",
                          { staticClass: "selectedFile-list mt-2" },
                          [
                            _vm._l(_vm.filesToUploadLater, function(file) {
                              return _c(
                                "li",
                                { key: file.name },
                                [
                                  _c("p", [_vm._v(_vm._s(file.name))]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeFileLater(file.name)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "24",
                                          height: "24",
                                          src: require("@/assets/img/ico-delete2.png")
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            _vm.filesToUploadLater.length === 0
                              ? [
                                  _c("li", [
                                    _c("p", [_vm._v("No Files selected")])
                                  ])
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "label-txt mt-4" }, [
                    _c("p", [_vm._v(" * Required Files")]),
                    _c("p", [_vm._v(" - One or more of the following")]),
                    _c("div", { staticClass: "ml-3" }, [
                      _c("p", [_vm._v(":Rogers Consumer Pricing Chart.xlsm")]),
                      _c("p", [_vm._v(":Fido Consumer Pricing Chart.xlsm")]),
                      _c("p", [
                        _vm._v(":Rogers and Fido Accessory Pricing Chart.xlsm")
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: {
                        text: "",
                        disabled: _vm.filesToUploadLater.length === 0,
                        icon: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.uploafiles("now")
                          _vm.Fileuploadpopup = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Upload Now")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: {
                        text: "",
                        disabled: _vm.filesToUploadLater.length === 0,
                        icon: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.uploafiles("later")
                          _vm.Fileuploadpopup = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Upload Later")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.Fileuploadpopup = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "500" },
          model: {
            value: _vm.MessageBoardpopup,
            callback: function($$v) {
              _vm.MessageBoardpopup = $$v
            },
            expression: "MessageBoardpopup"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "page-title-bar" }, [
                  _c(
                    "i",
                    {
                      staticClass: "ico",
                      staticStyle: { "margin-right": "10px" }
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "33",
                          height: "32",
                          src: require("@/assets/img/ico-tit-messageboard.png")
                        }
                      })
                    ]
                  ),
                  _vm._v(_vm._s(_vm.$t("Message Board")) + " ")
                ])
              ]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.messagetextdata,
                    expression: "messagetextdata"
                  }
                ],
                staticClass: "messageboardClass",
                staticStyle: { width: "100%" },
                domProps: { value: _vm.messagetextdata },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.messagetextdata = $event.target.value
                  }
                }
              }),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.messagetextdataSave()
                          _vm.MessageBoardpopup = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.MessageBoardpopup = false
                          _vm.cancelmessageboard()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "800" },
          model: {
            value: _vm.problemDialog,
            callback: function($$v) {
              _vm.problemDialog = $$v
            },
            expression: "problemDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "page-title-bar" }, [
                  _c("i", { staticClass: "ico ico-overview" }),
                  _vm._v(_vm._s(_vm.$t("Abnormal Store")) + " ")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    staticClass: "tbl-type01",
                    attrs: {
                      headers: _vm.translateHeaders(_vm.ProblemHeaders),
                      items: _vm.ProblemItems,
                      "hide-default-footer": true,
                      "item-key": "id",
                      "disable-pagination": "",
                      "fixed-header": "",
                      height: _vm.ProblemItems.length > 0 ? "560px" : "auto"
                    },
                    on: {
                      "page-count": function($event) {
                        _vm.pageCount = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.link",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goProblemLink(item)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/ico_link.png"),
                                      alt: "aaa"
                                    }
                                  })
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.problemDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.passwordPolicy
        ? _c(
            "v-dialog",
            {
              attrs: { width: "950", "max-height": "80%" },
              model: {
                value: _vm.dialog_passwordChange,
                callback: function($$v) {
                  _vm.dialog_passwordChange = $$v
                },
                expression: "dialog_passwordChange"
              }
            },
            [
              _c("v-card", { staticClass: "popup esl-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "popup-titbox mb-0",
                    staticStyle: { display: "flex", "flex-direction": "column" }
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass: "page-title-bar",
                        staticStyle: {
                          "font-weight": "600",
                          "margin-left": "-2px"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "25", color: "#001E38" } },
                          [_vm._v("mdi-lock")]
                        ),
                        _vm._v(_vm._s(_vm.$t("Change Temporary Password")))
                      ],
                      1
                    ),
                    _c("div", [
                      _vm._v(
                        "You logged in with a temporary password. Please enter a new password"
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex col-12 mt-5",
                    staticStyle: {
                      padding: "0px !important",
                      "margin-left": "-15px"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "popup-titbox mb-0 col-6",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("v-text-field", {
                              staticClass: "form-input",
                              staticStyle: {
                                width: "300px",
                                "font-weight": "600"
                              },
                              attrs: {
                                label: _vm.$t("New Password *"),
                                placeholder: _vm.$t("Input New Password"),
                                outlined: "",
                                dense: "",
                                type: "password",
                                "hide-details": ""
                              },
                              on: { input: _vm.passwordValidate },
                              model: {
                                value: _vm.newPassword,
                                callback: function($$v) {
                                  _vm.newPassword = $$v
                                },
                                expression: "newPassword"
                              }
                            }),
                            _c("v-text-field", {
                              staticClass: "form-input mt-5",
                              staticStyle: {
                                width: "300px",
                                "font-weight": "600"
                              },
                              attrs: {
                                label: _vm.$t("Confirm New Password *"),
                                placeholder: _vm.$t(
                                  "Input Confirm New Password"
                                ),
                                outlined: "",
                                dense: "",
                                type: "password",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.confirmnewPassword,
                                callback: function($$v) {
                                  _vm.confirmnewPassword = $$v
                                },
                                expression: "confirmnewPassword"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "popup-titbox mb-0 col-6",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column"
                        }
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "frmValidation" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                class: {
                                  iconClassNot: !_vm.has_length,
                                  iconClass: _vm.has_length
                                }
                              },
                              [_vm._v("mdi-checkbox-marked-circle")]
                            ),
                            _vm._v("Contains 8 to 15 characters.")
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "frmValidation mt-3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                class: {
                                  iconClassNot: !_vm.has_uppercase,
                                  iconClass: _vm.has_uppercase
                                }
                              },
                              [_vm._v("mdi-checkbox-marked-circle")]
                            ),
                            _vm._v("Include at least 1 uppercase letter.")
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "frmValidation mt-3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                class: {
                                  iconClassNot: !_vm.has_lowercase,
                                  iconClass: _vm.has_lowercase
                                }
                              },
                              [_vm._v("mdi-checkbox-marked-circle")]
                            ),
                            _vm._v("Include at least 1 lower case letter.")
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "frmValidation mt-3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                class: {
                                  iconClassNot: !_vm.has_number,
                                  iconClass: _vm.has_number
                                }
                              },
                              [_vm._v("mdi-checkbox-marked-circle")]
                            ),
                            _vm._v("Include at least 1 number.")
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "frmValidation mt-3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                class: {
                                  iconClassNot: !_vm.has_special,
                                  iconClass: _vm.has_special
                                }
                              },
                              [_vm._v("mdi-checkbox-marked-circle")]
                            ),
                            _vm._v("Include at least 1 special character."),
                            _c("br"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  "margin-left": "34px"
                                }
                              },
                              [
                                _vm._v(
                                  "(*List all special characters and not just etc)"
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "frmValidation mt-3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                class: {
                                  iconClassNot: _vm.has_consicutive,
                                  iconClass: !_vm.has_consicutive
                                }
                              },
                              [_vm._v("mdi-checkbox-marked-circle")]
                            ),
                            _vm._v(
                              "Not use 3 consecutive characters. (abc, 123)"
                            )
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "frmValidation mt-3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                class: {
                                  iconClassNot: _vm.has_repeat,
                                  iconClass: !_vm.has_repeat
                                }
                              },
                              [_vm._v("mdi-checkbox-marked-circle")]
                            ),
                            _vm._v("Not repeat 3 characters. (aaa, 777)")
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "frmValidation mt-3" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-4",
                                class: {
                                  iconClassNot: !_vm.has_space,
                                  iconClass: _vm.has_space
                                }
                              },
                              [_vm._v("mdi-checkbox-marked-circle")]
                            ),
                            _vm._v("Not use space.")
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { padding: "30px" } },
                  [
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-center mt-0" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn",
                            attrs: {
                              disabled: !_vm.savebutton,
                              text: "",
                              icon: ""
                            },
                            on: { click: _vm.savePasswordwithpolicy }
                          },
                          [_vm._v(_vm._s(_vm.$t("Save")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      !_vm.passwordPolicy
        ? _c(
            "v-dialog",
            {
              attrs: { width: "620", "max-height": "80%" },
              model: {
                value: _vm.dialog_passwordChange,
                callback: function($$v) {
                  _vm.dialog_passwordChange = $$v
                },
                expression: "dialog_passwordChange"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "popup esl-detail" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "popup-titbox mb-0",
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column"
                      }
                    },
                    [
                      _c("v-card-title", [
                        _c(
                          "h3",
                          {
                            staticClass: "page-title-bar",
                            staticStyle: {
                              "font-weight": "600",
                              "margin-left": "-2px"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { size: "25", color: "#001E38" } },
                              [_vm._v("mdi-lock")]
                            ),
                            _vm._v(_vm._s(_vm.$t("Change Temporary Password")))
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticStyle: { "font-weight": "200" } }, [
                        _vm._v(
                          "You logged in with a temporary password. Please enter a new password"
                        )
                      ])
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex col-12 mt-5",
                        staticStyle: { padding: "0px !important" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "popup-titbox mb-0",
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column"
                            }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-text-field", {
                                  staticClass: "form-input",
                                  staticStyle: {
                                    "font-weight": "600",
                                    width: "503px"
                                  },
                                  attrs: {
                                    label: _vm.$t("New Password *"),
                                    placeholder: _vm.$t("Input New Password"),
                                    outlined: "",
                                    dense: "",
                                    type: "password",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.newPassword,
                                    callback: function($$v) {
                                      _vm.newPassword = $$v
                                    },
                                    expression: "newPassword"
                                  }
                                }),
                                _c("v-text-field", {
                                  staticClass: "form-input mt-5",
                                  staticStyle: {
                                    width: "503px",
                                    "font-weight": "600"
                                  },
                                  attrs: {
                                    label: _vm.$t("Confirm New Password *"),
                                    placeholder: _vm.$t(
                                      "Input Confirm New Password"
                                    ),
                                    outlined: "",
                                    dense: "",
                                    type: "password",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.confirmnewPassword,
                                    callback: function($$v) {
                                      _vm.confirmnewPassword = $$v
                                    },
                                    expression: "confirmnewPassword"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-card-actions",
                        { staticClass: "d-flex justify-center mt-10" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: { text: "", icon: "" },
                              on: { click: _vm.savePasswordwithoutpolicy }
                            },
                            [_vm._v(_vm._s(_vm.$t("Save")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            width: "650",
            persistent: "",
            scrollable: "",
            "max-height": "90%"
          },
          model: {
            value: _vm.emailDialog,
            callback: function($$v) {
              _vm.emailDialog = $$v
            },
            expression: "emailDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup esl-detail" },
            [
              _c("v-card-title", [
                _c(
                  "div",
                  {
                    staticClass: "popup-titbox mb-0",
                    staticStyle: { display: "flex", "flex-direction": "column" }
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass: "page-title-bar",
                        staticStyle: {
                          "font-weight": "600",
                          "margin-left": "-2px"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "25", color: "#001E38" } },
                          [_vm._v("mdi-lock")]
                        ),
                        _vm._v(_vm._s(_vm.$t("Update E-mail")) + " ")
                      ],
                      1
                    ),
                    _c("div", { staticStyle: { "font-weight": "200" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "If you update your E-mail, you can easily find your password and receive your report by E-mail."
                            )
                          ) +
                          " "
                      )
                    ])
                  ]
                )
              ]),
              _c("v-card-text", [
                _c(
                  "div",
                  {
                    staticClass: "d-flex col-12 mt-5",
                    staticStyle: { padding: "0px !important" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "popup-titbox mb-0",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("v-text-field", {
                              staticClass: "form-input",
                              staticStyle: {
                                "font-weight": "600",
                                width: "503px"
                              },
                              attrs: {
                                label: "" + _vm.$t("E-Mail"),
                                placeholder: _vm.$t("Input the Email"),
                                outlined: "",
                                dense: "",
                                type: "text",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.updateEmail,
                                callback: function($$v) {
                                  _vm.updateEmail = $$v
                                },
                                expression: "updateEmail"
                              }
                            }),
                            _c("br"),
                            _c("br"),
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c("v-checkbox", {
                                  staticClass: "form-checkbox ml-2",
                                  attrs: { "hide-details": "" },
                                  on: { change: _vm.checkEmail },
                                  model: {
                                    value: _vm.checkedEmail,
                                    callback: function($$v) {
                                      _vm.checkedEmail = $$v
                                    },
                                    expression: "checkedEmail"
                                  }
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("Don't ask me again")))
                                ]),
                                _c("br"),
                                _c("br")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-center mt-3" },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn ml-3",
                              attrs: { text: "" },
                              on: { click: _vm.updateEmailAddress }
                            },
                            [_vm._v(_vm._s(_vm.$t("Update")) + " ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn ml-3",
                              attrs: { text: "" },
                              on: { click: _vm.updateEmailAddressLater }
                            },
                            [_vm._v(_vm._s(_vm.$t("Update Later")) + " ")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300" },
          model: {
            value: _vm.messageDialog,
            callback: function($$v) {
              _vm.messageDialog = $$v
            },
            expression: "messageDialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.messageText) }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.afterUpdatePassword }
                    },
                    [_vm._v(_vm._s(_vm.$t("OK")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }